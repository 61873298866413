/*---------------------------------------------------------------------------------------------
 *--------------------------------------------------------------------------------------------*/

import { Heading } from '@msdyn365-commerce-modules/utilities';
import { getRichTextHtml, IImageSettings, Image, RichTextComponent } from '@msdyn365-commerce/core';
import * as React from 'react';
import { ITastingToursData } from './all-purpose-tasting-tours.data';
import { IAllPurposeTastingToursProps } from './all-purpose-tasting-tours.props.autogenerated';

// =============================================================================
// INTERFACES
// =============================================================================

declare module 'react' {
    // tslint:disable-next-line:interface-name
    interface HTMLAttributes<T> extends DOMAttributes<T> {
        token?: string;
    }
}

// tslint:disable-next-line: no-any
declare var appInsights: any;

/**
 *
 * TastingTours component: Shows information about tasting flights and tours
 * offered and loads third-party application for making reservations
 * @extends {React.Component<IAllPurposeTastingToursProps<ITastingToursData>>}
 */
class TastingTours extends React.Component<IAllPurposeTastingToursProps<ITastingToursData>> {

    constructor(props: IAllPurposeTastingToursProps<ITastingToursData>) {
        super(props);
        this._showWidget = this._showWidget.bind(this);
    }

    // ========================================================================
    // PUBLIC METHODS
    // =========================================================================

    // ------------------------------------------------------
    // HACK: Disable logging to reservecloud domain
    // Without this, we're getting a CORS error because
    // appInsights is injecting resource-id into the header
    // of all AJAX calls.
    // ------------------------------------------------------
    public componentDidMount(): void {
        const domain = '*.reservecloud.com';

        // There are at least three paths, based on timing and async operations:
        // One: everything is initialized and we just need to add the target domain
        try {
            appInsights.config.correlationHeaderExcludedDomains.push(domain);
        } catch (e) {
            // Two: appInsights.config exists, but correlationHeaderExcludedDomains doesn't exist yet
            // tslint:disable-next-line: no-typeof-undefined
            if ((typeof appInsights !== 'undefined') && appInsights.config && !appInsights.config.correlationHeaderExcludedDomains) {
                appInsights.config.correlationHeaderExcludedDomains = [domain];
            } else {
                // THREE: appInsights is defined, but not appInsights.config. In this case we can either wait around,
                // or close our eyes and hope for the best.
                console.warn('AppInsights Domain Exclusion Failed');
            }
        }

        // Load the ReserveCloud script and inject it -- we can't do it via JSX
        // Pick a default target in case it's missing (even though it's required)
        this._injectScript(this.props.config.rcTarget || 'guestLists');
    }

    // ------------------------------------------------------
    // ------------------------------------------------------
    public render(): JSX.Element {

        return (
            <>
                <section className='tasting-tours'>
                    <div className='row'>
                        {this._image}

                        <div className={this._reservationClassName}>
                            {this._content}
                            {this._consentGate}
                            {this._integration}
                        </div>
                    </div>
                </section>
            </>
        );
    }

    // ==========================================================================
    // JSX CONSTRUCTORS
    // ==========================================================================

    // ------------------------------------------------------
    // Displays an optional image next to the content
    // ------------------------------------------------------
    private get _image(): JSX.Element | null {
        const { image } = this.props.config;

        const defaultImageSettings: IImageSettings = {
            viewports: {
                xs: { q: `w=24&h=24&m=6`, w: 0, h: 0 },
                lg: { q: `w=24&h=24&m=6`, w: 0, h: 0 }
            },
            lazyload: true
        };

        if (image) {
            return (
                <div className='col-sm-4'>
                    <figure className='tasting-tours-image'>
                        <Image
                            {...image}
                            gridSettings={this.props.context.request.gridSettings!}
                            imageSettings={image.imageSettings || defaultImageSettings}
                        />
                    </figure>
                </div>
            );
        }

        return null;
    }

    // ------------------------------------------------------
    // Display the reservation content
    // ------------------------------------------------------
    private get _content(): JSX.Element | null {

        return (
            <React.Fragment>
                {this._header}
                {this._body}
            </React.Fragment>
        );
    }

    // ------------------------------------------------------
    // Optional Header above body text
    // ------------------------------------------------------
    private get _header(): JSX.Element | null {
        const { header } = this.props.config;

        if (header && header.text) {
            return (
                <Heading
                    className='tasting-tours-header'
                    headingTag={header.tag}
                    text={header.text}
                />
            );
        }

        // No header specified
        return null;
    }

    // ------------------------------------------------------
    // Main body text
    // ------------------------------------------------------
    private get _body(): JSX.Element | null {
        const { content } = this.props.config;

        return (
            <div className='tasting-tours-content'>
                {content && // tslint:disable-next-line:react-no-dangerous-html
                    <div dangerouslySetInnerHTML={getRichTextHtml(content)} />}
            </div>

        );
    }

    // ------------------------------------------------------
    // ------------------------------------------------------
    private get _consentGate(): JSX.Element | null {
        const { consentCTA, consentText, requireConsent, consentFooter, consentFooterClass } = this.props.config;
        if (requireConsent) {
            return (
                <div id='tasting-tours-consent'>
                    <RichTextComponent text={consentText || ''} />
                    <button className='tasting-tours-button' onClick={this._showWidget}>{consentCTA}</button>
                    <RichTextComponent className={consentFooterClass} text={consentFooter || ''} />
                </div>
            );
        } else {
            return null;
        }
    }

    // ------------------------------------------------------
    // ReserveCloud-specific JSX
    // ------------------------------------------------------
    private get _integration(): JSX.Element {
        const { token, requireConsent } = this.props.config;

        return (
            <div className='tasting-tours-app'>
                <div id='rc-portal' className={requireConsent ? 'tasting-tours-hidden' : ''} token={token}>
                    <img src='https://www.reservecloud.com/images/loading.gif' alt='Loading' />
                </div>
            </div>
        );
    }

    // ==========================================================================
    // PRIVATE METHODS
    // ==========================================================================

    // ------------------------------------------------------
    // ------------------------------------------------------
    private get _reservationClassName(): string {
        // if there is an image available, then use sm-8 instead to match the
        // images sm-4
        return (this.props.config.image) ? 'col-sm-8' : 'col';
    }

    // ------------------------------------------------------
    // Load the ReserveCloud script and inject it -- we can't do it via JSX
    // ------------------------------------------------------
    private _injectScript(target: string): void {
        // tslint:disable-next-line: no-typeof-undefined
        if (typeof document !== 'undefined') {
            const script = document.createElement('script');
            script.defer = true;
            script.setAttribute('id', 'rc-script');
            script.setAttribute('rc-target', target);
            script.src = 'https://www.reservecloud.com/scripts/portals/rcPortal.js';
            document.getElementsByTagName('head')[0].appendChild(script);
        }
    }

    // ------------------------------------------------------
    // ------------------------------------------------------
    private _showWidget(): void {
        const widget = document.getElementById('rc-portal') as HTMLInputElement;
        const consentGate = document.getElementById('tasting-tours-consent') as HTMLInputElement;
        if (widget && consentGate) {
            widget.className = '';
            consentGate.className = 'tasting-tours-hidden';
        }
    }
}

export default TastingTours;
