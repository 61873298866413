import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IGetWineLocationQueryFilters } from './actions/get-wine-location';
import { IGetWineProductsData } from './actions/get-wine-products';
import { FindWineDropdownItem, FindWineDropdownMenu } from './wine-finder-dropdown-menu';
import { IPlaceholdersData, IWineFinderFormConfig, IWineFinderFormResources } from './wine-finder-form.props.autogenerated';

interface IWineFinderQueryLocationProps {
    defaultRadius: number;
    resources: IWineFinderFormResources;
    displayFilters: boolean;
    placeholders?: IPlaceholdersData;
    config: IWineFinderFormConfig;
    products: IGetWineProductsData[] | undefined;
    active?: boolean;
    onQuerySearch(filters: IGetWineLocationQueryFilters): unknown;
}

interface IWineFinderState{
    loading?:boolean;
}

/**
 * Class definition responsible for generating valid queries for getting wine
 */
@observer
class WineFinderQueryLocation extends React.Component<IWineFinderQueryLocationProps, IWineFinderState> {

    // =========================================================================
    // OBSERVABLES AND FIELDS
    // =========================================================================

    /**
     * field representing the location being typed in by the user
     */
    @observable private _queryLocation: string = '';

    /**
     * field represneting the radius or furthest distance from the location
     * typed in by the user
     */
    @observable private _queryRadius: number = 5;

    @observable private _allowRetail: boolean = false;
    @observable private _allowRestaurant: boolean = false;

    /**
     * keeps the selected product in the wine product dropdownlist
     */
    @observable private _product: string = '';

    /**
     * list of choosable radii to select in dropdown menu
     */
    private _queryRadii: number[] = [5, 10, 20, 50];

    // =========================================================================
    // PUBLIC METHODS
    // =========================================================================

    public constructor(props: IWineFinderQueryLocationProps) {
        super(props);
        this._handleQueryLocationChange = this._handleQueryLocationChange.bind(this);
        this._handleSearchRadiusChange = this._handleSearchRadiusChange.bind(this);
        this._toggleRetail = this._toggleRetail.bind(this);
        this._toggleRestaurant = this._toggleRestaurant.bind(this);
        this._doQuery = this._doQuery.bind(this);
        this._handleOnChange = this._handleOnChange.bind(this);

        this._allowRetail = !!props.config.defaultFilters?.retail;
        this._allowRestaurant = !!props.config.defaultFilters?.restaurant;
        this.state={
            loading: false
        };
    }

    public render(): JSX.Element {
        const itemList = this._queryRadii.map(radii => { return { label: radii, value: radii }; });
        const allowRadiiDisplay = this.props.defaultRadius === 0;
        const {
            resources: {
            wineFinder__domesticTitle,
            wineFinder__domesticSearchRadiusLabel,
            wineFinder__retailFilterText,
            wineFinder__restaurantFilterText
            }
        } = this.props;
        const { domesticSearch, enablePlaceholders } = this.props.placeholders || {};
        const isActive = this.props.active || false;

        const cssClass = isActive?'find-wine-query active':'find-wine-query';

        const onFormSubmit=(e: { preventDefault: any; })=>{
            e.preventDefault();
            this._doQuery();
        };

        return (
            <form onSubmit={onFormSubmit}>
                <div>
                    <div className={cssClass}>
                        {wineFinder__domesticTitle && <h2 className='find-wine-query-title'>{wineFinder__domesticTitle}</h2>}
                        <label
                            className='find-wine-query-location sr-only'
                            htmlFor='location'
                        >
                            {enablePlaceholders ? domesticSearch : ''}
                            </label>
                            <input
                                type='text'
                                id='location'
                                className='find-wine-query-location-input'
                                value={this._queryLocation}
                                onChange={this._handleQueryLocationChange}
                                placeholder={enablePlaceholders ? domesticSearch : ''}
                                required
                            />
                            {allowRadiiDisplay &&
                                <div className='find-wine-query-search-radius'>
                                    <label
                                        className='find-wine-query-search-radius-label'
                                        htmlFor='search-radius'
                                    >
                                        {wineFinder__domesticSearchRadiusLabel}

                                    </label>
                                    <FindWineDropdownMenu id='search-radius' defaultValue={this._queryRadius.toString()} itemList={itemList} onChange={this._handleSearchRadiusChange} />
                                </div>
                            }
                            {this.props.config.showChooseWineInput && this._renderProductsDropdownList()}
                            {
                                (this.props.displayFilters && this._hasFilterActive) &&
                                <div className='find-wine-query-search-filters'>
                                    <div className='find-wine-query-search-filter find-wine-query-search-filter-retail'>
                                        <label data-checked={this._allowRetail}>
                                            <input type='checkbox' onChange={this._toggleRetail} checked={this._allowRetail} />
                                            <span>{wineFinder__retailFilterText}</span>
                                        </label>
                                    </div>
                                    <div className='find-wine-query-search-filter find-wine-query-search-filter-restaurant'>
                                        <label data-checked={this._allowRestaurant}>
                                            <input type='checkbox' onChange={this._toggleRestaurant} checked={this._allowRestaurant} />
                                            <span>{wineFinder__restaurantFilterText}</span>
                                        </label>
                                    </div>
                                </div>
                            }
                        <button type='submit' className='find-wine-query-search-button' disabled={this._isInvalid()} onKeyPress={this._doQuery} onClick={this._doQuery} aria-label={'Search'}>{this.props.config.wineFinderSubmit || 'Find wine'}</button>
                    </div>
                    {(this._isInvalid() && this._queryLocation.length >= 5) && <div className='alert alert-danger text-input-validation-error'>{'Zip Code is invalid'}</div>}
                    {this.state.loading ? <div role='status' aria-live='polite' className='pt-3'>Loading Results...</div> : ''}
            </div>
        </form>
        );
    }

    // =========================================================================
    // PRIVATE METHODS
    // =========================================================================

    /**
     * process to send the query with appropriate fields to the parent component
     */
    private _doQuery(): undefined {
        this.setState({loading: true});
        let queryRadius = this.props.defaultRadius;
        if (queryRadius === 0) {
            queryRadius = this._queryRadius;
        }

        const filters: IGetWineLocationQueryFilters = {
            location: this._queryLocation,
            radius: queryRadius,
            on_premise: this._allowRestaurant
        };

        // if both are checked or both are unchecked search for everything
        if (this._allowRestaurant === this._allowRetail) {
            delete filters.on_premise;
        }

        if (!this._hasFilterActive) {
            const { filterState } = this.props.config;

            // idea here is essentially the author has forced the premise type boolean
            // through the configuration state by disabling all adjustable filters. thats
            // why logic looks small
            filters.on_premise = !!filterState?.restaurantActive;

            // just to make sure that it returns everything if both are unchecked
            if (!filterState?.restaurantActive && !filterState?.retailActive) {
                delete filters.on_premise;
            }
        }

        if (this.props.config.showChooseWineInput && this._product) {
            filters.product = this._product;
        }

        this.props.onQuerySearch(filters);
        return undefined;
    }

    private get _hasFilterActive(): boolean {
        const { filterState } = this.props.config;
        return !!(filterState?.restaurantActive && filterState?.retailActive);
    }

    /**
     * handles changes for the radius
     */
    private _handleSearchRadiusChange(item: FindWineDropdownItem | undefined): void {
        if (item) {
            this._queryRadius = Number(item.value);
        }
    }

    private _toggleRetail(): void {
        this._allowRetail = !this._allowRetail;
    }

    private _toggleRestaurant(): void {
        this._allowRestaurant = !this._allowRestaurant;
    }

    /**
     * handles changes for the location entered
     */
    private _handleQueryLocationChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this._queryLocation = event.target.value;
        return undefined;
    }

    private _isInvalid(): boolean {
        const regex = /^\d{5}$/;
        return (!regex.test(this._queryLocation));
    }

    /**
     * Display a Wine products dropdownlist
     */
    private _renderProductsDropdownList(): JSX.Element {
        return(
            <div className='find-wine-query-choose-wine'>
                <label htmlFor='choose-wine' className="sr-only">Choose Wine (optional)</label>
                <select className='find-wine-query-choose-wine-control form-control' id='choose-wine' value={this._product} onChange={this._handleOnChange}>
                    <option aria-selected={false} value=''>Choose Wine (optional)</option>
                    {this._renderProductOptions()}
                </select>
            </div>
            );
        }

    /**
     * handles for changing selection on the wine products dropdownlist
     *
     * @param event change event
     */
    private _handleOnChange(event: React.ChangeEvent<HTMLSelectElement>): void {
        this._product = event.target.value;
    }

    // Render dropdown options from array.
    private _renderProductOptions(): JSX.Element[] {
        const options: IGetWineProductsData[] | undefined = this.props.products ? this.props.products : [];
        return options.map((option, index) => {
            return (
                <option
                    key={index}
                    value={option.product_key}
                    aria-selected={false}
                >
                    {option.description}
                </option>
            );
        });
    }
}

export default WineFinderQueryLocation;
