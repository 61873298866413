/**
 * Class definition for the output controller of the API. Acts as a middle layer
 * and ensures that the data stays consistent internally
 */
export class WineMapper<Input> {
    protected _input: Input;
    constructor(input: Input) {
        this._input = input;
    }

    protected _applyMap<T, data, map>(
        inputData: data,
        inputMap: map
    ): T {
        const mappedOutput = {};
        Object.keys(inputMap).forEach(key => {
            mappedOutput[key] = inputData[inputMap[key]];
        });
        return <T>mappedOutput;
    }
}