import * as React from 'react';

interface IWineFinderPagerProps {
    totalPages: number;
    currentPage: number;
    onPageChange(pageChange: number): undefined;
}

/**
 * Component responsible for rendering the pagination aspect of the get wine
 * page
 */
class WineFinderPager extends React.Component<IWineFinderPagerProps> {

    // =========================================================================
    // PUBLIC METHODS
    // =========================================================================

    public render(): JSX.Element {

        const goAsMin = () => {
            this._goToMinMaxPage('min');
        };

        const goAsMax = () => {
            this._goToMinMaxPage('max');
        };

        return (
            <div className='find-wine-pager'>
                {/* tslint:disable-next-line: react-this-binding-issue */}
                <button className={`btn find-wine-pager-page-back ${this._isFirstPagination ? 'find-wine-pager-disabled' : ''}`} disabled={this._isFirstPagination ? true : false} onClick={goAsMin}>
                    «
                </button>
                {this._renderPages()}
                {/* tslint:disable-next-line: react-this-binding-issue */}
                <button className={`btn find-wine-pager-page-forward ${this._isLastPagination ? 'find-wine-pager-disabled' : ''}`} disabled={this._isLastPagination ? true : false} onClick={goAsMax}>
                    »
                </button>
            </div>
        );
    }

    // =========================================================================
    // PRIVATE METHODS
    // =========================================================================

    private get _isFirstPagination(): boolean {
        return this.range.lower === 0;
    }

    private get _isLastPagination(): boolean {
        return this.range.higher === this.props.totalPages;
    }
    /**
     * jump to the lowest or highest page
     */
    private _goToMinMaxPage(minOrMax: 'min' | 'max'): void {
        if (minOrMax === 'min') {
            this.props.onPageChange(1);
        } else {
            this.props.onPageChange(this.props.totalPages);
        }
    }

    /**
     * get the range (in bounds) of what indexes are to be rendered
     *
     * @returns bounds
     */
    private get range(): { lower: number; higher: number } {
        const currentPage = Number(this.props.currentPage);
        const lowerBound = Math.max(0, currentPage - 3);
        const higherBound = Math.min(this.props.totalPages, lowerBound + 5);
        return { lower: lowerBound, higher: higherBound };
    }

    /**
     * returns JSX elements representing a list of pages clickable and to go to
     */
    private _renderPages(): JSX.Element[] {
        const range = this.range;
        return Array(range.higher - range.lower).fill(0).map((value, index) => {
            const pageValue = range.lower + index + 1;
            if (pageValue === Number(this.props.currentPage)) {
                return (
                    // tslint:disable-next-line: jsx-no-lambda react-this-binding-issue
                    <button className='btn find-wine-pager-page find-wine-pager-page-active' key={index} onClick={() => this.props.onPageChange(pageValue)}>
                        {pageValue}
                    </button>
                );
            } else {
                return (
                    // tslint:disable-next-line: jsx-no-lambda react-this-binding-issue
                    <button className='btn find-wine-pager-page' key={index} onClick={() => this.props.onPageChange(pageValue)}>
                        {pageValue}
                    </button>
                );
            }
        });
    }
}

export default WineFinderPager;