import { IActionContext } from '@msdyn365-commerce/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import WineCountries, { GetWineCountriesInput, IGetWineCountriesDataOutput } from './actions/get-wine-countries';
import { IGetWineInternationalQueryFilters } from './actions/get-wine-international-location';
import { FindWineDropdownItem, FindWineDropdownMenu } from './wine-finder-dropdown-menu';
import { IWineFinderFormResources } from './wine-finder-form.props.autogenerated';

interface IWineFinderQueryLocationInternational {
    actionContext: IActionContext;
    resources: IWineFinderFormResources;
    submitText: string | undefined;
    active?: boolean;
    onQuerySearch(filters: IGetWineInternationalQueryFilters): unknown;
}
/**
 * class definition for the component responsible for querying internationally
 */
@observer
class WineFinderQueryLocationInternational extends React.Component<IWineFinderQueryLocationInternational> {

    /**
     * field representing the country key currently selected by the selector
     */
    @observable private _selectedCountry: string | undefined;

    @observable private _countries: IGetWineCountriesDataOutput[] = [];

    constructor(props: IWineFinderQueryLocationInternational) {
        super(props);

        this._handleCountrySelectionChange = this._handleCountrySelectionChange.bind(this);
        this._doQuery = this._doQuery.bind(this);

    }

    public componentDidMount(): void {
        this._queryCountries().catch(err => console.warn(err));
    }

    public render(): JSX.Element {
        const itemList = this._countries.map(country => {
            return {
                label: country.name,
                value: country.iso
            } as FindWineDropdownItem;
        });

        const {
            wineFinder__internationalTitle,
            wineFinder__internationalCountryLabel
        } = this.props.resources;
        const isActive = this.props.active || false;

        const cssClass = isActive?'find-wine-query-int active':'find-wine-query-int';

        return (
            <div className={cssClass}>
                <h2 className='find-wine-query-int-title'>{wineFinder__internationalTitle}</h2>
                <label
                    className='find-wine-query-int-country'
                    htmlFor='country'
                >
                    {wineFinder__internationalCountryLabel}
                </label>
                <FindWineDropdownMenu id='country' itemList={itemList} onChange={this._handleCountrySelectionChange} />
                <button className='find-wine-query-int-search-button' disabled={this._isInvalid} onClick={this._doQuery} >{this.props.submitText || 'Find wine'}</button>
            </div>
        );
    }

    /**
     * triggers the api call for country
     */
    private _doQuery(): void {
        if (!this._isInvalid) {
            this.props.onQuerySearch({
                country: this._selectedCountry as string
            });
        }
    }

    /**
     * sets the selected country to a new country code
     *
     * @param newCountry new country to switch to
     */
    private _handleCountrySelectionChange(newCountry: FindWineDropdownItem | undefined): void {
        if (newCountry) {
            this._selectedCountry = newCountry.value as string;
        }
    }

    private get _isInvalid(): boolean {
        return !this._selectedCountry;
    }

    private async _queryCountries(): Promise<void> {
        const input = new GetWineCountriesInput();
        const countries = await WineCountries(input, this.props.actionContext);
        this._countries = countries;
    }
}

export default WineFinderQueryLocationInternational;