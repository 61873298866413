import { observable, reaction, toJS } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IExpandedState } from './wine-finder-form';
import { IWineFinderFormConfig, IWineFinderFormResources, showProductsConfiguration } from './wine-finder-form.props.autogenerated';

interface IWineFinderWineDisplayProps {
    wines?: string[];
    resources: IWineFinderFormResources;
    config: IWineFinderFormConfig;
    allOpen: boolean;
}

/**
 * displays a list of wines
 */
@observer
class WineFinderWineDisplay extends React.Component<IWineFinderWineDisplayProps, IExpandedState> {
    @observable private _isOpen: boolean = false;

    private _wines?: string[];

    constructor(props: IWineFinderWineDisplayProps) {
        super(props);
        this._wines = props.wines;
        reaction(
            () => this.props.allOpen,
            (open) => {
                this._isOpen = open;
            }
        );

        this.state={
            expandedState:false
        };

        this._closeToggle = this._closeToggle.bind(this);
    }

    public render(): JSX.Element {
        const {
            hideProducts,
            showProducts
        } = this.props.config.showProductsLabel || {hideProducts:'Hide Products', showProducts:'Show Products'};
        return (
            <div className='find-wine-wine-display'>
                {
                    this._showButton && <button
                        className='find-wine-wine-display-toggle'
                        onClick={this._closeToggle}
                        aria-expanded={this.state.expandedState}
                    >
                        {(this._isOpen) ? hideProducts : showProducts}
                    </button>
                }
                {this._renderWineList()}
            </div>
        );
    }

    private get _showButton(): boolean {
        return this.props.config.showProductsConfiguration === showProductsConfiguration.showButton;
    }

    private get _showProducts(): boolean {
        return this.props.config.showProductsConfiguration === showProductsConfiguration.showProductsHideButton;
    }

    private _closeToggle(): void {
        this._isOpen = !this._isOpen;
        this.setState({
            expandedState: !this.state.expandedState
        });
    }

    private _renderWineList(): JSX.Element | undefined {

        const list: string[] = toJS(this._wines) as string[];
        list.sort();

        const wineList = (
            <ul className='find-wine-wine-display-wines'>
                {list.map((wine, index) => (<li key={index} className='find-wine-wine-display-wine'>{wine}</li>))}
            </ul>
        );

        if (this._showProducts && this._wines) { return wineList; }

        if (!this._isOpen || !this._wines) {
            return;
        }

        return wineList;
    }
}

export default WineFinderWineDisplay;
