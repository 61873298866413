import * as React from 'react';
import { IWineFinderFormConfig, IWineFinderFormResources } from './wine-finder-form.props.autogenerated';
import WineFinderWineDisplay from './wine-finder-wine-display';

interface IFindWineItemProps {
    distance?: number;
    website?: string;
    phoneNumber?: string;
    location: string;
    name: string;
    numberInList?: number;
    type: 'international' | 'US';
    mapData?: {
        address: string;
        zip: string;
    };
    wines?: string[];
    config: IWineFinderFormConfig;
    resources: IWineFinderFormResources;
    allOpen: boolean;
    hideNumbers?: boolean;
}

/**
 * creates tsx for a single retailer, READ ONLY
 *
 * @param props data on retailers
 */
export const FindWineItem = (props: IFindWineItemProps): JSX.Element => {
    const hideNumbers = props.hideNumbers || false;
    if (props.type === 'US') {
        return (
            <div className='find-wine-display-item-domestic'>
                <h2 className='find-wine-display-item-domestic-title'>
                    {!hideNumbers && `${props.numberInList}.`} {props.name}
                </h2>
                <div className='find-wine-display-item-domestic-info'>
                    <div className='find-wine-display-item-domestic-location'>{props.location}</div>
                    <div className='find-wine-display-item-domestic-group'>
                        <div className='find-wine-display-item-domestic-distance'>{props.distance} miles</div>
                        <a
                            target='_blank'
                            aria-label='Open the address in a new Google Maps window'
                            className='find-wine-display-item-domestic-map'
                            href={`https://maps.google.com/?q=${
                                (props.mapData as { address: string; zip: string }).address
                                },${
                                (props.mapData as { address: string; zip: string }).zip
                                },'${props.name}'`}
                                rel="noreferrer"
                        >{props.config.mapText || 'Show Map'}
                        </a>
                    </div>
                </div>
                <WineFinderWineDisplay
                    wines={props.wines}
                    config={props.config}
                    resources={props.resources}
                    allOpen={props.allOpen}
                />
            </div>
        );
    } else {
        return (
            <div className='find-wine-display-item-int'>
                <h2 className='find-wine-display-item-int-title'>{!hideNumbers && `${props.numberInList}.`} {props.name}</h2>
                <div className='find-wine-display-item-int-info'>
                    <div className='find-wine-display-item-int-location'>{props.location}</div>
                    <div className='find-wine-display-item-int-pn'>{props.phoneNumber}</div>
                    {
                        // hack for getting the '↵' symbol when no website is here
                        (props.website && props.website.length > 2) ? (
                            <a
                                target='_blank'
                                className='find-wine-display-item-int-website-link'
                                href={`//${props.website}`}
                                rel="noreferrer"
                            >
                                {props.resources.wineFinder__internationalWebsiteLabel}
                            </a>
                        ) : (<div />)
                    }
                </div>
            </div>
        );
    }
};