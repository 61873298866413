import { ICoreContext, RichText } from '@msdyn365-commerce/core';
import * as React from 'react';
import { IrsmFriendsAndFamilyCode } from '../../../dataActions/DataServiceEntities.g';
import { IFamilyAndFriendsConfig, IFamilyAndFriendsResources } from '../family-and-friends.props.autogenerated';
interface IFamilyAndFriendsCodeComponentProps {
    code: IrsmFriendsAndFamilyCode;
    config: IFamilyAndFriendsConfig;
    resources: IFamilyAndFriendsResources;
    // tslint:disable-next-line: no-any
    context: ICoreContext<{ [x: string]: any }>;
}

/**
 * renders a single row (code) for the family and friends table
 */
export class FamilyAndFriendsCodeComponent extends React.Component<IFamilyAndFriendsCodeComponentProps> {
    constructor(props: IFamilyAndFriendsCodeComponentProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <div className='family-friends__table-item'>
                <div className='family-friends__code'>
                    <div className='family-friends__code-label'>{this.props.resources.familyFriends_codeLabel}</div>
                    <div className='family-friends__code-value'>{this.props.code.Code}</div>
                </div>
                <div className='family-friends__recipient'>
                    <div className='family-friends__recipient-label'>{this.props.resources.familyFriends_recipientLabel}</div>
                    <div className='family-friends__recipient-value'>{this._recipientColumnAction}</div>
                </div>
                <div className='family-friends__activation'>
                    <div className='family-friends__activation-label'>{this.props.resources.familyFriends_activationDateLabel}</div>
                    <div className='family-friends__activation-value'>{this.props.code.ActivationDate}</div>
                </div>
            </div>
        );
    }

    private get _recipientColumnAction(): JSX.Element {
        if (this._hasBeenRecieved) {
            return <div className='family-friends__recipient-email'>{this.props.code.RecipientEmailAddress}</div>;
        }
        return (
            <div className='family-friends__recipient-notify'>
                <a href={this.mailToLink} className='family-friends__recipient-button'>{this.props.resources.familyFriends_sendToAFriendBtn}</a>
            </div>
        );
    }

    private get mailToLink(): string {
        const { mailSubject, mailBody } = this.props.config;
        return `mailto:?subject=${this._sanitizeMailTo(mailSubject, true)}&body=${this._sanitizeMailTo(mailBody)}${this.props.code.Code}`;
    }

    //-------------------------------------------------------
    // mailto doesn't support HTML tags, and is fairly picky
    // and limited.
    // The CMS places all text in <p> blocks, randomly converts
    // some spaces to &nbsp; and sanitizes certain characters
    // like single quotes (used as apostrophes!)
    // We need to convert all of that wackiness into something
    // that CMS authors would expect.
    //
    // stripOnly:
    //   The Mail Subject was created in the definition file
    //   as RichText, which was a bad idea. It's always a
    //   short, unformatted string. RichText is surrounded in
    //   a <p> tag, so for the mail subject we want to strip
    //   it instead of replacing with CRLF.
    //-------------------------------------------------------
    private _sanitizeMailTo(value: RichText | undefined, stripOnly?: boolean): string {
        if (!value) { return ''; }

        return value
            .replace(/<p>/g, '')                                // Remove opening <p> tags
            .replace(/<\/p>/g, stripOnly ? '' : '%0D%0A%0D%0A') // Remove or convert closing <p> tags
            .replace(/&nbsp;/g, ' ')                            // Convert nbsp; to space
            .replace(/<br \/>/g, '%0D%0A')                      // Convert <br /> to %0D%0A
            .replace(/&#(\d\d);/g,                              // Convert &#dd; values to %xx format (decimal --> hex)
                     (match, p1) => {return `%${(+p1).toString(16)}`;});
    }

    private get _hasBeenRecieved(): boolean {
        return !!this.props.code.RecipientEmailAddress;
    }
}