import * as Msdyn365 from '@msdyn365-commerce/core';
import { apiToken } from './get-wine-api-token';

/**
 * Class Definition for constructing the wine location query
 */
export class GetWineFilterable<Filters> extends Msdyn365.CommerceEntityInput implements Msdyn365.IActionInput {

    public queryFilters: Filters;

    private readonly _token: string = apiToken;

    // TODO: Determine if the results of this get action should cache the results and if so provide
    // a cache object type and an appropriate cache key
    constructor(filters: Filters) {
        super({
            dataCacheType: 'request',
            cacheObjectType: 'IGetWineLocationData',
            cacheKey: JSON.stringify(filters)
        });
        this.queryFilters = filters;
        return this;
    }

    get token(): string {
        return this._token;
    }

    /**
     * generates an http styled query string to append to the end of a url
     *
     * @returns http query string
     */
    public filtersToHTTPQueryString(): string {
        let queryString = '';
        Object.keys(this.queryFilters).forEach(queryKey => {
            const queryValue = this.queryFilters[queryKey];
            queryString += `${queryKey}=${queryValue}&`;
        });
        queryString = queryString.substr(0, queryString.length - 1);
        return (queryString.length > 0) ? `?${queryString}` : '';
    }
}