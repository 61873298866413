import React from 'react';
import {
    IGetWineInternationalDistributorOuput,
    IGetWineInternationalLocationOutput,
    IGetWineInternationalQueryFilters
} from './actions/get-wine-international-location';
import {
    IGetWineLocationDataOutput,
    IGetWineLocationProductOutput,
    IGetWineLocationQueryFilters,
    IGetWineLocationRetailerOutput
} from './actions/get-wine-location';
import { FindWineItem } from './wine-finder-display-item';
import { IWineFinderFormConfig, IWineFinderFormResources } from './wine-finder-form.props.autogenerated';

type wineResultContent = (IGetWineLocationRetailerOutput | IGetWineInternationalDistributorOuput)[];

interface IWineFinderScrollableProps {
    showMoreText: string;
    resultType: 'retailer' | 'distributor';
    queryFilters: IGetWineLocationQueryFilters | IGetWineInternationalQueryFilters;
    wineLocationData: IGetWineLocationDataOutput | IGetWineInternationalLocationOutput;
    wineResults: wineResultContent;
    config: IWineFinderFormConfig;
    resources: IWineFinderFormResources;
    allOpen: boolean;
    hideNumbers?: boolean;
    onGetMoreContent(filters: IGetWineLocationQueryFilters | IGetWineInternationalQueryFilters): Promise<void>;
}

/**
 * uses a "show more" feature to add to the current wine results instead of
 * pagination
 */
export class WineFinderScrollable extends React.Component<IWineFinderScrollableProps> {

    constructor(props: IWineFinderScrollableProps) {
        super(props);

        this._onAddMore = this._onAddMore.bind(this);
    }

    public render(): JSX.Element {
        const pageCount = this.props.wineLocationData.totalPages;
        const page = this.props.queryFilters.page || 1;
        return (
            <div className='find-wine-results'>
                <div className='find-wine-results-page'>
                    {this._renderWineResults()}
                </div>
                {
                    page < pageCount &&
                    <button
                        onClick={this._onAddMore}
                        className='find-wine-results-show-more'
                    >
                        {this.props.showMoreText}
                    </button>
                }
            </div>
        );
    }

    /**
     * renders a list of wine locations
     *
     * @returns array of JSX Elements to render
     */
    private _renderWineResults(): JSX.Element[] {
        const hideNumbers = this.props.hideNumbers || false;

        if (!this.props.wineResults.length) {
            return [(<div key={'wineFinderNoResults'} className='wine-finder-no-results'>{this.props.resources.wineFinder__noResults}</div>)];
        }

        if (this.props.resultType === 'retailer') {
            return (this.props.wineResults as IGetWineLocationRetailerOutput[]).
                map((retailer, key) =>
                    (
                        <FindWineItem
                            config={this.props.config}
                            type='US'
                            location={`${retailer.address} ${retailer.city}, ${retailer.state}. ${retailer.zip}`}
                            name={retailer.name}
                            distance={retailer.distance}
                            key={retailer.code}
                            numberInList={((this.props.wineLocationData.page - 1) * this.props.config.perPage!) + (key + 1)}
                            mapData={
                                {
                                    address: retailer.address,
                                    zip: retailer.zip
                                }
                            }
                            wines={this._getProducts(retailer.products)}
                            resources={this.props.resources}
                            allOpen={this.props.allOpen}
                            hideNumbers={hideNumbers}
                        />
                    )
                );
        } else {
            // mapping function that returns an array of jsx retailers
            return (this.props.wineResults as IGetWineInternationalDistributorOuput[])
                .map((distributor) =>
                    (
                        <FindWineItem
                            config={this.props.config}
                            type='international'
                            location={`${distributor.city}, ${distributor.country.iso}`}
                            name={distributor.name}
                            phoneNumber={distributor.phone}
                            website={distributor.url}
                            key={distributor.jdeDistributorNumber}
                            resources={this.props.resources}
                            allOpen={this.props.allOpen}
                            hideNumbers={hideNumbers}
                        />
                    )
                );
        }
    }

    /**
     * returns the name of products, if it exists
     *
     * @param productArray array of wines
     */
    private _getProducts(productArray: IGetWineLocationProductOutput[]): string[] {
        return productArray.map(product => product.description);
    }

    private async _onAddMore(): Promise<void> {
        await this.props.onGetMoreContent(this.props.queryFilters);
    }
}