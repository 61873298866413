//import { observable, toJS } from 'mobx';
//import { observer } from 'mobx-react';
import * as React from 'react';
import { IGetWineInternationalLocationOutput, IGetWineInternationalQueryFilters } from './actions/get-wine-international-location';
import { IGetWineLocationDataOutput, IGetWineLocationProductOutput, IGetWineLocationQueryFilters } from './actions/get-wine-location';
import { FindWineItem } from './wine-finder-display-item';
import { IWineFinderFormConfig, IWineFinderFormResources } from './wine-finder-form.props.autogenerated';
import WineFinderPager from './wine-finder-pager';

interface IWineFinderResultsProps {
    resultType: 'retailer' | 'distributor';
    queryFilters: IGetWineLocationQueryFilters | IGetWineInternationalQueryFilters;
    wineLocation: IGetWineLocationDataOutput | IGetWineInternationalLocationOutput;
    resources: IWineFinderFormResources;
    config: IWineFinderFormConfig;
    allOpen: boolean;
    hideNumbers?: boolean;
    onPageChange(filters: IGetWineLocationQueryFilters | IGetWineInternationalQueryFilters): void;
}

/**
 * displays results from a get wine location query
 */
class WineFinderResults extends React.Component<IWineFinderResultsProps> {

    // =========================================================================
    // PUBLIC METHODS
    // =========================================================================

    public constructor(props: IWineFinderResultsProps) {
        super(props);

        this._handlePageChange = this._handlePageChange.bind(this);
    }

    public render(): JSX.Element {
        return (
            <div className='find-wine-results'>
                <div className='find-wine-results-page'>
                    {this._renderWineLocations()}
                    <div className='find-wine-results-page-info'>
                        <div className='find-wine-results-page-info-border'>
                            {
                                this.props.wineLocation.data.length &&
                                <div className='find-wine-results-page-info-border-page-count'>Displaying {this.props.wineLocation.page} of {this.props.wineLocation.totalPages} pages</div>

                            }
                            {
                                this.props.wineLocation.data.length &&
                                <WineFinderPager
                                    currentPage={this.props.wineLocation.page}
                                    totalPages={this.props.wineLocation.totalPages}
                                    onPageChange={this._handlePageChange}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // =========================================================================
    // PRIVATE METHODS
    // =========================================================================

    /**
     * handler for changing page
     *
     * @param pageChange the number that the page will change to
     */
    private _handlePageChange(pageChange: number): undefined {
        const newQuery = this.props.queryFilters;
        newQuery.page = pageChange;
        this.props.onPageChange(newQuery);
        return undefined;
    }

    /**
     * renders a list of wine locations
     *
     * @returns array of JSX Elements to render
     */
    private _renderWineLocations(): JSX.Element[] {
        const hideNumbers = this.props.hideNumbers || false;
        if (!this.props.wineLocation.data.length) {
            return [(<div key={'wineFinderNoResults'} className='wine-finder-no-results'>{this.props.resources.wineFinder__noResults}</div>)];
        }
        switch (this.props.resultType) {
            case 'retailer':
                return (this.props.wineLocation as IGetWineLocationDataOutput).data.
                    map((retailer, key) =>
                        (
                            <FindWineItem
                                config={this.props.config}
                                type='US'
                                location={`${retailer.address} ${retailer.city}, ${retailer.state}. ${retailer.zip}`}
                                name={retailer.name}
                                distance={retailer.distance}
                                key={retailer.code}
                                numberInList={((this.props.wineLocation.page - 1) * this.props.config.perPage!) + (key + 1)}
                                mapData={
                                    {
                                        address: retailer.address,
                                        zip: retailer.zip
                                    }
                                }
                                wines={this._getProducts(retailer.products)}
                                resources={this.props.resources}
                                allOpen={this.props.allOpen}
                                hideNumbers={hideNumbers}
                            />
                        )
                    );
            default:
                // mapping function that returns an array of jsx retailers
                return (this.props.wineLocation as IGetWineInternationalLocationOutput).data
                    .map((distributor) =>
                        (
                            <FindWineItem
                                config={this.props.config}
                                type='international'
                                location={`${distributor.city}, ${distributor.country.iso}`}
                                name={distributor.name}
                                phoneNumber={distributor.phone}
                                website={distributor.url}
                                key={distributor.jdeDistributorNumber}
                                resources={this.props.resources}
                                allOpen={this.props.allOpen}
                                hideNumbers={hideNumbers}
                            />
                        )
                    );
        }
    }

    /**
     * returns the name of products, if it exists
     *
     * @param productArray array of wines
     */
    private _getProducts(productArray: IGetWineLocationProductOutput[]): string[] {
        return productArray.map(product => product.description);
    }
}

export default WineFinderResults;