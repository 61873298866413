import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';

export type FindWineDropdownItem = { label: string | number; value: string | number };

interface IFindWineDropdownMenuProps {
    itemList: FindWineDropdownItem[];
    defaultValue?: string;
    id?: string;
    onChange(newItem: FindWineDropdownItem | undefined): unknown;
}

/**
 * class definition for creating a dropdown menu for the wine page
 */
@observer
export class FindWineDropdownMenu extends React.Component<IFindWineDropdownMenuProps> {

    /**
     * field representation on the current value of what is selected
     */
    @observable private _value: string = this.props.defaultValue || '';
    public constructor(props: IFindWineDropdownMenuProps) {
        super(props);

        this._handleOnChange = this._handleOnChange.bind(this);
    }

    public render(): JSX.Element {
        let items = this._constructItems();
        if (!this._value) {
            items = [
                (
                    <option
                        value={''}
                        key={''}
                        aria-selected={true}
                        className='find-wine-dropdown-country'
                    >
                        {'Select Country'}
                    </option>
                )
                , ...items];
        }
        return (
            <select className='find-wine-query-int-dropdown' aria-label={this._value === '' ? 'Select Country' : this._value} value={this._value} onChange={this._handleOnChange}>
                {items}
            </select>
        );
    }

    /**
     * handles for changing selection
     *
     * @param event change event
     */
    private _handleOnChange(event: React.ChangeEvent<HTMLSelectElement>): void {
        const newItem = this.props.itemList.find(item => event.target.value === item.value.toString());
        this._value = event.target.value;
        this.props.onChange(newItem);
    }

    /**
     * constructs a list of JSX elements representing options for a select tag
     */
    private _constructItems(): JSX.Element[] {
        return this.props.itemList.map(
            (item, index) => {
                const isAriaSelected = item.value === this._value;
                return (
                    <option
                        value={item.value}
                        key={index}
                        aria-selected={isAriaSelected}
                        className='find-wine-dropdown-country'
                    >
                        {item.label}
                    </option>
                );
            }
        );
    }
}